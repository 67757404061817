.gt-dashboard-container {
	position: relative;
	top: 57px;

	.gt-dashboard-sidebar {
		position: fixed;
		top: 57px;
		bottom: 0;
		left: 0;
		z-index: 1000;
		padding: 20px 0;
		overflow-x: hidden;
		overflow-y: auto;
		background-color: #f7f7f7;
		border-right: 1px solid #eee;
		min-width: 300px;
		box-sizing: border-box;

		.gt-nav-item {
			width: 100%;

			.gt-nav-link {
				display: block;
				padding: 8px 20px;
				font-size: 16px;
				&:hover {
					color: #0275d8;
				}
			}

			.gt-active,
			.gt-active:hover,
			.gt-active:focus {
				color: #fff;
				background-color: #0275d8;
				cursor: default;
			}
		}
	}

	.gt-dashboard-contents {
		margin-left: 300px;
		padding: 5px 20px;

		.gt-dashboard-title {
			border-bottom: 1px solid #eee;
			font-size: 36px;
			font-weight: 500;
            margin-bottom: 20px;
		}

		h2 {
			font-size: 30px;
			font-weight: 500;
            margin-bottom: 5px;
		}

        section {
            margin-bottom: 40px;
        }
	}

}