.gt-hamburger {
	position: absolute;
	top: 0;
	right: 0;
	width: 46px;
	height: 44px;
	cursor: pointer;
	-webkit-transform: translate3d(0px,0px,0px);
	-moz-transform: translate3d(0px,0px,0px);
	-ms-transform: translate3d(0px,0px,0px);
	-webkit-transition-property: -webkit-transform;
	-webkit-transition-timing-function: cubic-bezier(0.6,0,0.2,1);
	-moz-transition-property: -moz-transform;
	-moz-transition-timing-function: cubic-bezier(0.6,0,0.2,1);
	-ms-transition-property: -ms-transform;
	-ms-transition-timing-function: cubic-bezier(0.6,0,0.2,1);

	> div > p:nth-child(1) {
	    top: 10px;
	    -webkit-transform: translate3d(0px,0px,0px) rotate(0deg);
	    -moz-transform: translate3d(0px,0px,0px) rotate(0deg);
	    -ms-transform: translate3d(0px,0px,0px) rotate(0deg);
	}

	> div > p:nth-child(2) {
	    top: 20px;
	    -webkit-transform: translate3d(0px,0px,0px) scaleX(1);
	    -moz-transform: translate3d(0px,0px,0px) scaleX(1);
	    -ms-transform: translate3d(0px,0px,0px) scaleX(1);
	}

	> div > p:nth-child(3) {
	    top: 30px;
	    -webkit-transform: translate3d(0px,0px,0px) rotate(0deg);
	    -moz-transform: translate3d(0px,0px,0px) rotate(0deg);
	    -ms-transform: translate3d(0px,0px,0px) rotate(0deg);
	}

	> div > p {
	    display: block;
	    width: 40px;
	    height: 4px;
	    border-radius:4px;
	    background-color:#676767;
	    position: absolute;
	    z-index: 95;
	    left: 3px;
	    -webkit-transition-property: -webkit-transform,background-color;
	    -webkit-transition-duration: .3s;
	    -webkit-transition-timing-function: cubic-bezier(0.6,0,0.2,1);
	    -moz-transition-property: -moz-transform,background-color;
	    -moz-transition-duration: .3s;
	    -moz-transition-timing-function: cubic-bezier(0.6,0,0.2,1);
	    -ms-transition-property: -ms-transform,background-color;
	    -ms-transition-duration: .3s;
	    -ms-transition-timing-function: cubic-bezier(0.6,0,0.2,1);
	}

	&.gt-active > div > p:nth-child(1) {
	    -webkit-transform: translate3d(0px,9px,0px) rotate(-45deg);
	    -moz-transform: translate3d(0px,9px,0px) rotate(-45deg);
	    -ms-transform: translate3d(0px,9px,0px) rotate(-45deg);
	}

	&.gt-active > div > p:nth-child(2) {
	    -webkit-transform: translate3d(0px,0px,0px) scaleX(0);
	    -moz-transform: translate3d(0px,0px,0px) scaleX(0);
	    -ms-transform: translate3d(0px,0px,0px) scaleX(0);
	}

	&.gt-active > div > p:nth-child(3) {
	    -webkit-transform: translate3d(0px,-11px,0px) rotate(45deg);
	    -moz-transform: translate3d(0px,-9px,0px) rotate(45deg);
	    -ms-transform: translate3d(0px,-9px,0px) rotate(45deg);
	}

}