/* gtris-progress */
.gt-progress {

    overflow: hidden;
    height: $progress-height;
    background-color: $progress-bgColor;
    border-radius: $progress-border-radius;
    @include box-shadow( inset 0 1px 2px rgba(0, 0, 0, 0.1) );

    > .gt-progress-bar {
        float: left;
        width: 0%;
        height: 100%;
        font-size: $progress-active-fontSize;
        line-height: $progress-active-lineHeight;
        color: $progress-active-color;
        background-color: $progress-active-bgColor;
        text-align: center;
        @include box-shadow( inset 0 1px 2px rgba(0, 0, 0, 0.15) );
        @include transition( width 0.6s ease );

        &.gt-progress-striped {
            @include gradient-striped( $progress-striped-color, $progress-striped-deg );
            background-size: $progress-striped-bgSize;
        }
    }

}