/* login */
.gt-login {
	width: 400px;
	padding: 5% 0 0;
	margin: auto;

	.gt-login-form {

		.gt-login-title {
			text-align: center;
			font-size: 40px;
			margin-bottom: 30px;
			color: #2985db;
		}

		.gt-remember {
			color: #888;
		}

		.gt-textbox {
			font-size: 14px;
		    width: 100%;
		    margin: 2px 6px 12px 0;
		    min-height: 50px;
			box-sizing: border-box;
			border-color: #dadada;
		}

		.gt-login-submit {
			width: 100%;
			min-height: 60px;
			margin-top: 30px;
			font-size: 18px;
			border: none;
			box-sizing: border-box;
		}

		.gt-login-submit:hover {
			background-color: #4a92d5;
		}

	}
}