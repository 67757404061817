/* navbar */
.gt-navbar {
	position: relative;
	margin-bottom: 20px;
	background-color: #eee;
	z-index: 1100;

	&.gt-active .gt-navbar-header .gt-navbar-nav {
		display: block;
	}

	.gt-navbar-header {
		@include clearfix();

		.gt-navbar-project-name {
			display: inline-block;
			float: left;
			padding: 15px;
			margin-left: -15px;
			font-size: 18px;
			color: #555;
			font-weight:bold;
		}

		.gt-navbar-nav {
			float: left;
			@include clearfix();

			&.gt-navbar-nav-right {
				float: none;

				> li:last-child > a {
					padding-right: 0;
				}
			}

			> li {
				float: left;
			}

			> li > a {
				display: block;
				padding: 18px 15px;
				font-size: 14px;
				color: #555;
				font-weight: bold;

				&:hover {
					color: #2985db;
				}
			}

			> li.gt-active > a {
				color: #2985db;
			}

			&.gt-navbar-nav-right {
				float: right;
			}
		}
	}

	&.gt-navbar-fixed {
		position: fixed;
		top: 0;
		width: 100%;
	}

	//color-inverse
	&.gt-navbar-inverse {
		background-color: #292929;

		.gt-navbar-project-name {
			color: #fff;
		}

		.gt-navbar-nav {
			float: left;

			> li > a {
				color: #9d9d9d;

				&:hover {
					color: #fff;
				}
			}

			> li.gt-active > a {
				color: #fff;
			}
		}
	}
}