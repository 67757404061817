/* gtris-modal */
.gt-modal-wrapper {
	
	.gt-modal {
		z-index: 1010;
		position: fixed;
		left: 50%;
		top: 50%;
		width: $modal-width;
		background: $modal-bgColor;
		@include transition-duration(.3s);

		.gt-modal-header {
			padding: $modal-header-padding;
			font-size: $modal-header-fontSize;
			color: $modal-header-color;
			background: $modal-header-bgColor;
			font-weight: bold;
		 }

		.gt-modal-body {
			padding: $modal-body-padding;
			max-height: $modal-body-maxHeight;
			color: $modal-body-color;
			overflow-y: auto;
		 }

		.gt-modal-close {
			position: absolute;
			top: $modal-close-top;
			right: $modal-close-right;
		}	
	}
	
}