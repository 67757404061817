/* gtris-dropdown */
.gt-dropdown {
    position: relative;

    .gt-dd-button {
        background: #2985db;
        color: #fff;
        padding: 16px 26px;
        font-size: 14px;

        &:hover,
        &:focus {
            background: #1061ad;
        }
    }

    &.gt-active .gt-dd-menu {
        display: block;
    }

    .gt-dd-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 160px;
        padding: 5px 0;
        margin: 2px 0 0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: #fff;
        border: 1px solid #ccc;
        background-clip: padding-box;
        box-shadow: 4px 4px 10px #ccc;

        > li > a {
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: normal;
            color: #333;
            white-space: nowrap;

            &:hover,
            &:focus {
                text-decoration: none;
                color: #333;
                background-color: #f5f5f5;
            }
        }

        .gt-dd-divider {
            height: 1px;
            margin: 9px 0;
            overflow: hidden;
            background-color: #e5e5e5;
        }
    }

    .gt-dd-caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 10px;
        vertical-align: middle;
        border-top:   4px solid;
        border-right: 4px solid transparent;
        border-left:  4px solid transparent;
    }
}
